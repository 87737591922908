import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Faqs() {
  return (
    <Layout>
      <SEO
        keywords={[
          `quod`,
          `quiz`,
          `e-learning`,
          `training`,
          `testing`,
          `creator`,
          `tools`,
          `faqs`,
        ]}
        title="FAQs"
      />

      <div className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold text-quod-purple-600 sm:text-4xl sm:leading-10">
            Frequently asked questions
          </h2>
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl>
              <div className="md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Is Quod suitable for me?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    If you have a learning management system (LMS), or other
                    applications capable of delivering SCORM files (including
                    Microsoft SharePoint), and you have a requirement to create
                    quizzes then Quod is the solution for you.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Is Quod available in other languages?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    The editing tools are currently available in English only,
                    but quizzes can be created in any language.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Can I try Quod before I buy it?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Yes, we'd love for you to try Quod. You can get a completely
                    free trial of Quod for 30 days, sign up{" "}
                    <a href="/pricing">here</a> today.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Does everyone on my team need a subscription?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Anyone who wants to create quizzes with Quod will need a
                    subscription.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Can I transfer my Quod subscription or my employee's
                  subscription to someone else?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Yes! Contact the Quod team and we’ll arrange to transfer for
                    you.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Do I have to buy Quod in GBP, can I buy in my currency?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Although the pricing on our site is in GBP, at the point of
                    checkout this will be converted into your local currency by
                    our payment provider (Stripe).
                  </p>
                </dd>
              </div>{" "}
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Do I need an LMS?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Yes! A user can create unlimited quizzes with Quod and
                    distribute these via their selected LMS to any number of
                    people at no additional cost.
                  </p>
                </dd>
              </div>{" "}
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  Do quizzes I create appear automatically online?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    No. You’ll need to export your quiz from Quod (a one-click
                    process), ready to import directly into your LMS, or similar
                    application capable of launching SCORM files.
                  </p>
                </dd>
              </div>{" "}
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-quod-purple-600 md:col-span-5">
                  How can I track quiz scores?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Quod quizzes are exported as SCORM zip files and these will
                    automatically track / report quiz scores back into the LMS,
                    or other applications capable of delivering SCORM files
                    (including Microsoft SharePoint), that they were launched
                    from.
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Faqs
